import { render, staticRenderFns } from "./AppSubmenu.vue?vue&type=template&id=d1d6cafa&scoped=true&"
import script from "./AppSubmenu.vue?vue&type=script&lang=js&"
export * from "./AppSubmenu.vue?vue&type=script&lang=js&"
import style0 from "./AppSubmenu.vue?vue&type=style&index=0&id=d1d6cafa&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d1d6cafa",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppSubmenu: require('/codebuild/output/src1147345887/src/Cloud-AQMS-ServicePoint/src/qengine-servicepoint-ui/components/AppLayout/AppSubmenu.vue').default})
