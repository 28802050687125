import { render, staticRenderFns } from "./AppMenu.vue?vue&type=template&id=f6e0075a&"
import script from "./AppMenu.vue?vue&type=script&lang=js&"
export * from "./AppMenu.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppSubmenu: require('/codebuild/output/src1147345887/src/Cloud-AQMS-ServicePoint/src/qengine-servicepoint-ui/components/AppLayout/AppSubmenu.vue').default})
