import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _66469266 = () => interopDefault(import('../pages/acknowledgement.vue' /* webpackChunkName: "pages/acknowledgement" */))
const _a1771b88 = () => interopDefault(import('../pages/active-queues.vue' /* webpackChunkName: "pages/active-queues" */))
const _62865a80 = () => interopDefault(import('../pages/customer-feedback-layout.vue' /* webpackChunkName: "pages/customer-feedback-layout" */))
const _7d6c64c0 = () => interopDefault(import('../pages/customer-feedback-one.vue' /* webpackChunkName: "pages/customer-feedback-one" */))
const _2adfa29c = () => interopDefault(import('../pages/ended-queues.vue' /* webpackChunkName: "pages/ended-queues" */))
const _36907f22 = () => interopDefault(import('../pages/feedback-acknowledgement.vue' /* webpackChunkName: "pages/feedback-acknowledgement" */))
const _4c6e3b77 = () => interopDefault(import('../pages/help-function.vue' /* webpackChunkName: "pages/help-function" */))
const _b576e4f2 = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _5b0f6f87 = () => interopDefault(import('../pages/missed-queues.vue' /* webpackChunkName: "pages/missed-queues" */))
const _4a2da719 = () => interopDefault(import('../pages/queue-details.vue' /* webpackChunkName: "pages/queue-details" */))
const _88f319f8 = () => interopDefault(import('../pages/rate-our-service.vue' /* webpackChunkName: "pages/rate-our-service" */))
const _718f1b4c = () => interopDefault(import('../pages/search-queues.vue' /* webpackChunkName: "pages/search-queues" */))
const _7fb46e8f = () => interopDefault(import('../pages/service-dashboard.vue' /* webpackChunkName: "pages/service-dashboard" */))
const _b1f88d02 = () => interopDefault(import('../pages/stored-queues.vue' /* webpackChunkName: "pages/stored-queues" */))
const _31e3310a = () => interopDefault(import('../pages/toggle-call.vue' /* webpackChunkName: "pages/toggle-call" */))
const _2243b136 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/acknowledgement",
    component: _66469266,
    name: "acknowledgement"
  }, {
    path: "/active-queues",
    component: _a1771b88,
    name: "active-queues"
  }, {
    path: "/customer-feedback-layout",
    component: _62865a80,
    name: "customer-feedback-layout"
  }, {
    path: "/customer-feedback-one",
    component: _7d6c64c0,
    name: "customer-feedback-one"
  }, {
    path: "/ended-queues",
    component: _2adfa29c,
    name: "ended-queues"
  }, {
    path: "/feedback-acknowledgement",
    component: _36907f22,
    name: "feedback-acknowledgement"
  }, {
    path: "/help-function",
    component: _4c6e3b77,
    name: "help-function"
  }, {
    path: "/logout",
    component: _b576e4f2,
    name: "logout"
  }, {
    path: "/missed-queues",
    component: _5b0f6f87,
    name: "missed-queues"
  }, {
    path: "/queue-details",
    component: _4a2da719,
    name: "queue-details"
  }, {
    path: "/rate-our-service",
    component: _88f319f8,
    name: "rate-our-service"
  }, {
    path: "/search-queues",
    component: _718f1b4c,
    name: "search-queues"
  }, {
    path: "/service-dashboard",
    component: _7fb46e8f,
    name: "service-dashboard"
  }, {
    path: "/stored-queues",
    component: _b1f88d02,
    name: "stored-queues"
  }, {
    path: "/toggle-call",
    component: _31e3310a,
    name: "toggle-call"
  }, {
    path: "/",
    component: _2243b136,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
